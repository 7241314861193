import { IModal } from '@/components';
import { IInput, ISelect } from '@/components/form';
import router from '@/router';
import store from '@/store';
import { ITranslations } from '@/translations';

export const modal: IModal = {
  isLarge: false,
  isActive: true,
  isCard: true,
  close: () => {
    router.push(
      `/event/${store.getters.getContextEvent.id}/sessions`,
    );
  },
};

interface IFilters {
  search: IInput;
  type: ISelect;
  registration: ISelect;
}

export const filters = () => {
  const translations: ITranslations = store.getters.getTranslations;

  const filters: IFilters = {
    search: {
      id: 'filter-search',
      name: 'filter_search',
      value: '',
      placeholder: translations.common.search,
      type: 'text',
    },
    type: {
      id: 'filter-search',
      name: 'filter_search',
      value: 'event',
      options: [{
        label: translations.visitors.sessions.event_sessions,
        value: 'event',
      }, {
        label: translations.visitors.sessions.exhibitor_sessions,
        value: 'exhibitor',
      }, {
        label: translations.visitors.sessions.all_sessions,
        value: 'all',
      }],
      type: 'text',
    },
    registration: {
      id: 'filter-registration',
      name: 'filter_registration',
      value: 'public',
      options: [{
        label: 'Public',
        value: 'public',
      }, {
        label: 'Private',
        value: 'private',
      }],
      type: 'text',
    },
  };

  return filters;
};
