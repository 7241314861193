// @ is an alias to /src
import { MutationPayload } from 'vuex';
import store from '@/store';
import Loading from '@/components/loading.vue';
import router from '@/router';
import { ISession, ISessionParams, IStage } from '@/models/session';
import {
  getSessionRegistrations, sessionRegistrationUpdate, getCalendarBlocks, getSessions, getStages,
} from '@/api/sessions';
import Input from '@/components/form/input.vue';
import Select from '@/components/form/select.vue';
import SessionCard from '@/partials/SessionCard.vue';
import isMobileDevice from '@/utils/isMobileDevice';
import { filters } from './index';
import { computed, defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';

const staging = process.env.VUE_APP_SERVER_PATH === 'https://b.beamian.com/api';

export default defineComponent({
  components: {
    Loading, Input, Select, SessionCard,
  },
  data() {
    return {
      upcomingSessions: [] as ISession[],
      openDay: 0,
      event: 0,
      filters: filters(),
      search: '',
      calendarBlocks: [] as string[],
      currentType: 'event',
      currentDay: '',
      firstLoad: true,
      mySessions: false,
      sidebarFixed: false,
      selectedStage: 0 as number | undefined,
      stages: [] as IStage[],
      daysOfSessions: [] as Date[],
    }
  },

  computed: {
    sessionsSorted(): ISession[] {
      return this.sortSessions(this.sessions);
    },
  },

  watch: {
    $route() {
      const selectedStage = parseInt(router.currentRoute.query.stage as string, 10) || undefined;
      if (selectedStage && this.selectedStage !== selectedStage) {
        this.selectedStage = selectedStage;
        this.getSessions({
          event: this.event,
          type: this.currentType,
          page: 1,
          page_size: 1000,
          is_published: true,
          stage: this.selectedStage,
          reset: true,
          hide_on_calendar: false,
        });
      } else if (!selectedStage && this.selectedStage) {
        this.selectedStage = undefined;
        this.getSessions({
          event: this.event,
          type: this.currentType,
          page: 1,
          page_size: 1000,
          is_published: true,
          reset: true,
          hide_on_calendar: false,
        });
      }
    }

  },

  setup() {
    const sessions = computed(() => store.getters.getSessions);
    const sessionsState = computed(() => store.getters.getSessionsState);
    const { translations, contextVisitor, userAdmin, language, contextEvent } = useContext();
    return { translations, sessions, sessionsState, language, contextVisitor, userAdmin, contextEvent }
  },


  created() {
    this.event = parseInt(this.$route.params.event, 10);
    this.selectedStage = parseInt(router.currentRoute.query.stage as string, 10);

    this.getSessions({
      event: this.event,
      type: this.currentType,
      page: 1,
      page_size: 1000,
      is_published: true,
      stage: this.selectedStage,
      reset: true,
      hide_on_calendar: false,
    });

    getStages(this.event).then((response) => {
      this.stages = response.data.results;
    });

    this.initInputs();
    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setLanguage') {
        this.filters = filters();
        this.initInputs();
      }
    });
    const unsubscribe = store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setSessions') {
        unsubscribe();
      }
    });
    window.addEventListener('scroll', this.fixSiderbar);
    window.addEventListener('resize', this.fixSiderbar);
  },

  methods: {
    getDaysOfSessions() {
      const daysOfSessions = [] as Date[];
      const daysOfSessionsStrings = [] as string[];
      this.calendarBlocks.forEach((date) => {
        const newDate = new Date(date);
        const newDateString = newDate.toISOString();
        if (!daysOfSessionsStrings.includes(newDateString)) {
          daysOfSessions.push(newDate);
          daysOfSessionsStrings.push(newDateString);
        }
      });
      this.daysOfSessions = daysOfSessions;
    },

    sessionHasValidDates(session: ISession) {
      const currentDate = new Date();
      const sessionStartDate = new Date(session.start_date);
      const sessionEndDate = new Date(session.end_date);
      sessionStartDate.setMinutes(sessionStartDate.getMinutes() - 15);
      const sessionStarted = sessionStartDate <= currentDate;
      const sessionIsFinished = currentDate >= sessionEndDate;
      return staging || this.userAdmin || (sessionStarted && !sessionIsFinished);
    },

    sortSessions(sessions: ISession[]) {
      return sessions.sort((prev, next) => {
        if (next.order !== undefined && prev.order !== undefined) {
          if (prev.order < next.order) {
            return -1;
          }
          if (prev.order > next.order) {
            return 1;
          }
        }
        return 0;
      });
    },

    loadMySessions() {
      this.mySessions = true;
      this.currentType = 'all';
      this.filters.type = {
        ...this.filters.type,
        value: 'all',
      };
      this.getSessions({
        event: this.event,
        type: this.currentType,
        page: 1,
        page_size: 1000,
        stage: this.selectedStage,
        is_published: true,
        registered: this.userAdmin ? undefined : true,
        visitor: this.contextVisitor.id,
        search: this.search !== '' ? this.search : undefined,
        reset: true,
        hide_on_calendar: false,
      });
    },

    loadEventSessions() {
      this.mySessions = false;
      this.currentType = 'event';
      this.filters.type = {
        ...this.filters.type,
        value: 'event',
      };
      this.firstLoad = true;
      this.getSessions({
        event: this.event,
        type: this.currentType,
        page: 1,
        page_size: 1000,
        stage: this.selectedStage,
        is_published: true,
        search: this.search !== '' ? this.search : undefined,
        reset: true,
        hide_on_calendar: false,
      });
    },

    initInputs() {
      this.filters.type.onChange = (value: string) => this.getSessions({
        event: this.event,
        type: value,
        page: 1,
        page_size: 1000,
        is_published: true,
        search: this.search !== '' ? this.search : undefined,
        reset: true,
        pending: false,
      });
      this.filters.registration.onChange = () => this.getSessions({
        event: this.event,
        type: this.currentType,
        page: 1,
        page_size: 1000,
        is_published: true,
        pending: false,
        search: this.search !== '' ? this.search : undefined,
        reset: true,
      });
      let searchTimeout = 0;
      this.filters.search.onInput = (value: string | number) => {
        this.search = value as string;
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {
          this.getSessions({
            page: this.sessionsState.page,
            event: this.event,
            type: this.currentType,
            page_size: 10,
            search: this.search !== '' ? this.search : undefined,
            start_date: this.currentDay,
            is_published: true,
            reset: true,
          });
        }, 300);
      };
    },

    fixSiderbar() {
      const section = document.getElementById('calendar-wrapper');
      const sidebar = document.getElementById('upcoming-sessions');
      if (!sidebar || !section || isMobileDevice() || window.outerWidth < 768) {
        return false;
      }

      const scrollTop = section.getBoundingClientRect().top;
      if (section.offsetHeight > sidebar.offsetHeight) {
        if (scrollTop < 64 && !this.sidebarFixed) {
          const width = section.offsetWidth / 4;
          sidebar.style.width = `${width}px`;
          this.sidebarFixed = true;
        } else if (scrollTop > 64 && this.sidebarFixed) {
          section.style.height = 'auto';
          this.sidebarFixed = false;
        }
      }
      return true; // No purpose, just for the linter
    },

    getTime(date: string) {
      const newDate = new Date(date);
      return newDate.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
    },

    getSessions(params: ISessionParams) {
      const today = new Date();
      const todayString = `${today.toISOString().split('.')[0]}Z`;
      this.currentType = params.type ? params.type : '';
      this.openDay = 0;
      if (this.firstLoad) {
        getSessions({
          event: this.event,
          type: 'all',
          page: 1,
          page_size: 2,
          pending: false,
          end_date_gte: todayString,
          stage: params.stage,
          reset: true,
          hide_on_calendar: false,
        }).then((response) => {
          this.upcomingSessions = response.data.results.filter((session) => session.name !== 'check-in' && session.name !== 'checkin');
        });
      }
      getCalendarBlocks({
        event: this.event,
        session_type: params.type,
        visitor: this.mySessions ? this.contextVisitor.id : undefined,
        page: 1,
        search: this.search !== '' ? this.search : undefined,
        page_size: 10,
        stage: params.stage,
        registered: this.mySessions && !this.userAdmin ? true : undefined,
        is_published: true,
        hide_on_calendar: false,
        calendar_blocks_timezone_id: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }).then((response) => {
        const diffdate = new Date();

        this.calendarBlocks = response.data;
        this.getDaysOfSessions();
        diffdate.setHours(0, 0, 0, 0);

        if (this.calendarBlocks[0]) {
          // eslint-disable-next-line prefer-destructuring
          // Get the day closest to today
          const today = new Date();
          today.setHours(0);
          today.setMinutes(0);
          const temp = this.daysOfSessions.map((d) => Math.abs(new Date(d).getTime()) - today.getTime());
          // const index = temp.indexOf(Math.min(...temp));
          const closest = temp.reduce(function (prev, curr) {
            return (Math.abs(curr - 0) < Math.abs(prev - 0) ? curr : prev);
          });
          const index = temp.indexOf(closest);
          this.changeDay(this.calendarBlocks[index], index);
        } else {
          store.commit('setSessionsReset');
          if (this.firstLoad) {
            this.changeSessionType();
          }
        }
        this.firstLoad = false;
      });
    },

    changeSessionType() {
      this.getSessions({
        event: this.event,
        type: 'exhibitor',
        page: 1,
        page_size: 10,
        stage: this.selectedStage,
        is_published: true,
        hide_on_calendar: false,
        search: this.search !== '' ? this.search : undefined,
      });
      const unsubscribe = store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'setSessions') {
          unsubscribe();
          this.filters.type = {
            ...this.filters.type,
            value: 'exhibitor',
            options: [{
              label: this.translations.visitors.sessions.event_sessions,
              value: 'event',
            }, {
              label: this.translations.visitors.sessions.exhibitor_sessions,
              value: 'exhibitor',
              checked: true,
            }, {
              label: this.translations.visitors.sessions.all_sessions,
              value: 'all',
            }],
          };
        }
      });
    },

    changeDay(day: string, i: number) {
      this.openDay = i;
      this.currentDay = day;
      store.dispatch('fetchSessions', {
        event: this.event,
        type: this.currentType,
        page: 1,
        page_size: 10,
        start_date: day,
        stage: this.selectedStage,
        registered: this.mySessions && !this.userAdmin ? true : undefined,
        visitor: this.mySessions ? this.contextVisitor.id : undefined,
        is_published: true,
        search: this.search !== '' ? this.search : undefined,
        hide_on_calendar: false,
      });
      this.$forceUpdate();
    },

    nextPage() {
      if (this.sessionsState.page) {
        const params: ISessionParams = {
          page: this.sessionsState.page + 1,
          event: this.event,
          type: this.currentType,
          page_size: 10,
          start_date: this.currentDay,
          is_published: true,
          stage: this.selectedStage,
          reset: false,
          search: this.search !== '' ? this.search : undefined,
          hide_on_calendar: false,
        };
        store.dispatch('fetchSessions', params);
      }
    },

    selectSession(session: ISession) {
      const sessionId = session.id;
      getSessionRegistrations({
        session: sessionId,
        visitor: this.contextVisitor.id,
      }).then(
        (response) => {
          const registrations = response.data.results.filter((registration) => registration.session === sessionId);
          const sessionRegistration = registrations.length > 0 ? registrations[0] : undefined;
          if (!this.sessionHasValidDates(session) || session.registration_capacity === 0) {
            router.push(
              `/event/${this.event}/sessions/session/${sessionId}`,
            );
          } else {
            if (sessionRegistration && sessionRegistration.status !== 5 && !this.userAdmin) {
              if (session.is_published) {
                if (session.embedded) {
                  router.push(`/event/${this.event}/session/${session.id}`);
                } else if (session.external_link) {
                  window.open(session.external_link, '_blank');
                } else {
                  router.push(
                    `/event/${this.event}/sessions/session/${sessionId}`,
                  );
                }
              } else {
                router.push(
                  `/event/${this.event}/sessions/session/${sessionId}`,
                );
              }
              // Enter a session
              sessionRegistrationUpdate({ ...sessionRegistration, status: 2 });
            } else {
              router.push(
                `/event/${this.event}/sessions/session/${sessionId}`,
              );
            }
          }
        },
        (error) => {
          // Could not retrieve registration. Enable registration.
          console.log(error);
          router.push(
            `/event/${this.event}/sessions/session/${sessionId}`,
          );
        },
      );
    }
  }
});


