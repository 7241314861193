




















































































































import { ISession } from '@/models/session';
import { defineComponent, PropType } from '@vue/composition-api';
import useContext from '@/composition/context';

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<ISession>,
      required: true,
    },
    imageHidden: Boolean,
    isLarge: Boolean,
    role: String,
    showExhibitor: Boolean,
    route: String,
  },
  data() {
    return {
      event: 0,
      exhibitor: 0,
      sessionLive: false,
    };
  },
  setup() {
    const { translations, language } = useContext();
    return { translations, language };
  },

  created() {
    this.event = parseInt(this.$route.params.event, 10);
    this.exhibitor = parseInt(this.$route.params.exhibitor, 10);
    this.checkSessionLive();
  },
  methods: {
    getTime(date: string) {
      const newDate = new Date(date);
      return newDate.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
    },

    checkSessionLive() {
      if (this.config.start_date) {
        const sDate = Date.parse(this.config.start_date);
        const eDate = Date.parse(this.config.end_date);
        const cDate = Date.parse(new Date().toISOString());
        if (cDate <= eDate && cDate >= sDate) {
          this.sessionLive = true;
          this.$forceUpdate();
        }
      }
    },

    getDate(date: string) {
      const newDate = new Date(date);
      return newDate.toLocaleString(this.language, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },

    getDay(date: string) {
      const newDate = new Date(date);
      return newDate.toLocaleString(this.language, { weekday: 'long' });
    },
  },
});
