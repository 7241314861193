var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"session-card"},[_c('router-link',{staticClass:"card",attrs:{"to":_vm.route
        ? _vm.route
        : ("/event/" + _vm.event + (_vm.role === 'exhibitor' ? '/manage-exhibitor' : '') + "/sessions/session/" + (_vm.config.id))}},[(!_vm.imageHidden)?_c('figure',{staticClass:"image"},[(!_vm.config._image)?_c('img',{attrs:{"src":require("@/assets/default_session_image.png"),"alt":"","loading":"lazy"}}):_vm._e(),(_vm.config._image)?_c('img',{attrs:{"src":_vm.config._image.file,"alt":"","loading":"lazy"}}):_vm._e()]):_vm._e(),_c('h6',{staticClass:"title session-name",class:{ 'is-6': !_vm.isLarge, 'is-5': _vm.isLarge }},[_vm._v(" "+_vm._s(_vm.config.name)+" ")]),_c('p',{staticClass:"subtitle session-day"},[_vm._v(" "+_vm._s(_vm.getDate(_vm.config.start_date))+" ")]),_c('p',{staticClass:"session-time"},[_vm._v(" "+_vm._s(_vm.getTime(_vm.config.start_date))+" - "+_vm._s(_vm.getTime(_vm.config.end_date))+" ")]),(
        _vm.config.exhibitor_name ||
        _vm.config.total_attendings >= _vm.config.registration_capacity ||
        _vm.sessionLive
      )?_c('div',{staticClass:"tags",staticStyle:{"margin-top":"0.75rem"}},[(_vm.config.exhibitor && _vm.role !== 'exhibitor' && _vm.showExhibitor)?_c('router-link',{staticClass:"tag is-black",attrs:{"to":("/event/" + _vm.event + "/exhibitor/" + (_vm.config.exhibitor))}},[_vm._v(" "+_vm._s(_vm.config.exhibitor_name)+" ")]):_vm._e(),(
          _vm.config.registration_capacity !== 0 &&
          _vm.config.total_attendings >= _vm.config.registration_capacity
        )?_c('small',{staticClass:"tag is-warning"},[_vm._v(" "+_vm._s(_vm.translations.common.session_full)+" ")]):_vm._e(),(_vm.sessionLive)?_c('span',{staticClass:"tag is-danger"},[_vm._v(" LIVE ")]):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }